@import url("https://fonts.googleapis.com/css2?family=Expletus+Sans&display=swap");

html {
  height: 100%;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
}

body {
  height: 100%;
  margin: 0;
}
